@import '../constant.scss';


@mixin setWidth() {
  &[data-width="date"] {
    width: 120px;
  }

  &[data-width="target"] {
    width: 90px;
  }

  &[data-width="state"] {
    width: 120px;
  }

  &[data-width="auto"] {
    flex: 1;
  }
  
  &[data-width="tel"],
  &[data-width="fax"] {
    width: 140px;
  }

  &[data-width="period"] {
    width: 300px;
  }
}

.table {
  border-spacing: 0;
  width: 100%;

  thead {
    th {
      color: $colorLabelInput;
      font-size: 1.2rem;
      padding: 0 $chartSpace $chartSpace 0;
      text-align: left;

      &:first-child {
        padding-left: $chartSpace;
      }

      @include setWidth;
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: $chartBg;
      }

      td {
        padding: $chartSpace;
        padding-left: 0;
        text-align: left;

        &:first-child {
          padding-left: $chartSpace;
        }
      }

      &[data-state="tmp"],
      &[data-state="private"] {
        background: rgba(255, 54, 158, .1)!important;
      
        td[data-strong="true"] {
          color: $colorTextError;
        }
      }
 
    }
  }
}


.head {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    color: $colorLabelInput;
    font-size: 1.2rem;
    font-weight: bold;
    padding: $chartSpace;
    text-align: left;
    
    @include setWidth;
  }
}

.ul {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;

    &:nth-child(odd) {
      background-color: $chartBg;
    }

    & > span {
      @include setWidth;
      
      padding: $chartSpace;
    }

    &[data-state="tmp"],
    &[data-state="private"] {
      background: rgba(255, 54, 158, .1)!important;
    
      & > span[data-strong="true"] {
        color: $colorTextError;
      }
    }
  }
}

.flexTitle {
  display: flex;
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;

  li {
    &:last-child {
      margin-left: auto;
    }

    h3 {
      margin: 0;
    }
  }
}
