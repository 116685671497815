@import '../constant.scss';

@keyframes textJ {
  0% {opacity: 0; transform: translate3d(0, -140px, 0);}
  10% {opacity: 0;}
  50% {opacity: 1; transform: translate3d(0, 0, 0);}
  90% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes textA1 {
  0% {opacity: 0; transform: translate3d(0, -140px, 0);}
  15% {opacity: 0;}
  65% {opacity: 1; transform: translate3d(0, 0, 0);}
  90% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes textA2 {
  0% {opacity: 0; transform: translate3d(0, -140px, 0);}
  30% {opacity: 0;}
  80% {opacity: 1; transform: translate3d(0, 0, 0);}
  90% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes textM {
  0% {opacity: 0; transform: translate3d(0, -140px, 0);}
  45% {opacity: 0;}
  90% {opacity: 1; transform: translate3d(0, 0, 0);}
  100% {opacity: 0;}
}

.screen {
  background: white;
  display: block;
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition:
    height .1s linear .3s,
    opacity .2s linear,
    width .1s linear .3s;
  width: 0;
  z-index: 99;

  svg {
    $size: 80px;

    display: block;
    height: $size;
    left: 50%;
    margin-left: -($size / 2);
    margin-top: -($size / 2);
    position: absolute;
    top: 50%;
    width: $size;

    path {
      fill: $logoSvgTextColor;
      opacity: 0;
      
      &:first-child {
        fill: $logoBgColor;
        opacity: 1;
      }
    }
  }

  &[data-loading="true"] {
    height: 100vh;
    opacity: 1;
    transition: opacity .2s linear;
    width: 100%;

    svg {
      path {
        &:not(:first-child) {
          animation-duration: 1.8s;
          animation-timing-function: ease-out;
          animation-iteration-count: infinite;
        }
  
        &:nth-of-type(2) {
          animation-name: textJ;
        }
  
        &:nth-of-type(3) {
          animation-name: textA1;
        }
  
        &:nth-of-type(4) {
          animation-name: textA2;
        }
  
        &:nth-of-type(5) {
          animation-name: textM;
        }
      }
    }
  }
}
