@import '../constant.scss';

.section {
  font-size: 1.2rem;
  line-height: 1.5;
  width: $guestFrameW;


  *[data-component="input"] {
    display: block;
    margin-top: 20px;

    input {
      width: 100%;
    }
  }
}

.button {
  text-align: right;
}