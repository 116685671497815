@import '../constant.scss';

.main {
  .date {
    color: rgba(0, 0, 0, .4);
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
}

.area {
  .button {
    width: 110px;
    padding: 10px 20px;
  }
}


.indent {
  &[data-indent="1"] {
    @include chartIndent;
  }
}
