@import '../constant.scss';
@import '../image.scss';

.main {
  h2 {
    margin-bottom: 60px;
  }
}


.file {
  margin: 60px 0 0;

  ul {
    list-style: circle;
    margin: 0;
    padding: 0 0 0 20px;

    li {
      margin-bottom: 14px;

      a {
        background: no-repeat right center;
        cursor: pointer;
        display: inline-block;
        padding-right: 20px;
        text-decoration: none;
        transition: opacity .2s linear;

        &:hover {
          opacity: .4;
        }

        &[data-icon="image"] {
          background-image: $imageIconImage;      
          background-size: auto 16px;     
        }

        &[data-icon="other"] {
          background-image: $imageIconFile;
          background-size: auto 16px;     
        }
      }
    }
  }
}


