@import '../constant.scss';

.at {
  div {
    color: $inputLabelColor;
    display: inline-block;
    font-size: 1.2rem;
    margin-right: 40px;
    
    span {
      color: $colorBlue;
    }
  }
}

.file {
  flex: 1;
}

.caption {
  width: 300px;
}

.join {
  width: 1200px;
}

.joinName {
  width: 200px;
}

.joinPeriod {
  width: 436px;
  white-space: nowrap;

  small {
    white-space: normal;
  }
}

.joinNote {
  width: 160px;
}

.checkBoxWrap {
  white-space: nowrap;

  & > div:last-child {
    margin-right: 0;
  }
}

.joinState {
  width: 160px;
}

.joinDate {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0; 
}

.inputMargin {
  margin-bottom: 34px;
}


.flatButton {
  @include buttonFlat;
  @include buttonHover;
}
