@import '../constant.scss';
@import '../image.scss';

$bottomMargin: 34px;
$labelBottomMargin: 8px;
$inputHeight: 30px;

@mixin label() {
  label {
    color: $inputLabelColor;
    display: block;
    font-size: 1.1rem;
    margin-bottom: $labelBottomMargin;
    vertical-align: top;

    &[data-required="true"]:after {
      content: "※";
      color: $inputRequiredColor;
      font-size: 1rem;
    }
  }
}

@mixin frame($h: $inputHeight) {
  background: white;
  border: solid 1px $inputBorderColor;
  border-radius: $inputRadius;
  color: $colorText;
  display: inline-block;
  font-size: 1.1rem;
  height: $h;
  line-height: 1.2rem;
  max-width: 100%;
  outline: none;
  padding: 8px;
  position: relative;
  transition: border-color .4s linear;
  vertical-align: top;
  z-index: 2;

  &[data-change="true"] {
    background: rgba(255, 100, 100, .1);
  }
}

@mixin readOnly() {
  color: $colorText;
  display: inline-block;
  font-size: 1.1rem;
  height: $inputHeight;
  line-height: 1.2rem;
  padding: 8px 0;
  vertical-align: top;
}

@mixin error() {
  position: relative;
  
  small {
    color: $inputErrorColor;
    font-size: 1rem;
    left: 0;
    position: absolute;
    top: calc(100% + 4px);
  }
}

.text {
  @include label;
  @include error;

  display: inline-block;
  margin: 0 0 $bottomMargin;
  max-width: 100%;

  input {
    @include frame;

    &:read-only {
      border: none;
      background: transparent;
      padding-left: 0;
      padding-right: 0;
    }

    &:focus {
      border-color: $inputFocusColor;
    }

    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &[data-input-error="true"] {
    input {
      border-color: $inputErrorColor!important;
    }
  }

  &[data-entered="true"] label {
  }
}

.checkbox {
  $boxSize: 14px;

  display: inline-block;
  margin: 0 16px $bottomMargin 0;

  input {
    display: none;
  }

  label {
    color: $inputLabelColor;
    cursor: pointer;
    display: inline-block;
    font-size: 1.2rem;
    padding-left: $boxSize + 10px;
    position: relative;

    &::before {
      border-top: solid 2px $inputBorderColor;
      border-bottom: solid 2px $inputBorderColor;
      border-left: solid 2px $inputBorderColor;
      border-right: solid 2px $inputBorderColor;
      box-sizing: border-box;
      content: "";
      display: block;
      height: $boxSize;
      left: 0;
      position: absolute;
      top: 0;
      transition: all .2s ease-out;
      transform: translate3d(0, 0, 0) rotate(0);
      width: $boxSize;
    }
  }

  input:checked + label::before {
    border-top-color: transparent;
    border-bottom-color: $inputCheckedColor;
    border-left-color: transparent;
    border-right-color: $inputCheckedColor;
    transform: translate3d($boxSize / 3, -($boxSize / 4), 0) rotate(35deg);
    width: ($boxSize / 3) * 2;
  }

  &[data-space="true"] {
    padding-top: 25px;
  }
}


.radio {
  $radioSize: 14px;

  display: inline-block;
  margin: 0 16px $bottomMargin 0;
  position: relative;

  input {
    display: none;
  }

  label {
    color: $inputLabelColor;
    cursor: pointer;
    padding-left: 20px;

    &::before,
    &::after {
      border-radius: 50%;
      box-sizing: border-box;
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
    }

    &::before {
      border: solid 2px $inputBorderColor;
      height: $radioSize;
      width: $radioSize;
      z-index: 1;
    }

    &::after {
      background: $colorRadioInput;
      height: $radioSize;
      transform: translate3d(0, 0, 0) scale(0, 0);
      transition: all .2s ease-out;
      width: $radioSize;
      z-index: 2;
    }
  }

  input:checked + label::after {
    transform: translate3d(0, 0, 0) scale(1.1, 1.1);
  }
}


.textarea {
  @include label;
  @include error;

  display: inline-block;
  margin: 0 0 $bottomMargin;

  textarea {
    @include frame(auto);
    line-height: 1.6rem;

    &:focus {
      border-color: $inputFocusColor;
    }
  }

  &[data-input-error="true"] {
    textarea {
      border-color: $inputErrorColor!important;
    }
  }
}

@mixin button() {
  appearance: none;
  background-color: $buttonBgColor;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
  border: none;
  border-radius: $inputRadius;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 1.2rem;
  letter-spacing: .15rem;
  outline: none;
  padding: 10px 20px;
  text-decoration: none;

  &[data-type="remove"] {
    background-color: $buttonStrongBgColor;
  }

  &[data-type="print"],
  &[data-type="download"] {
    background-color: $buttonLightBgColor;
  }

  @include buttonHover;
}

.button {
  display: inline-block;
  a,
  span,
  button {
    @include button;
  }
}


.select {
  @include label;
  
  display: inline-block;
  margin: 0 0 $bottomMargin;

  select {
    @include frame;

    appearance: none;
    background: no-repeat white $imageSelectArrow;
    background-size: auto 12px;
    background-position: right 8px center;
    padding-right: 20px;

    &::-ms-expand {
      display: none;
    }
  }


}

.file {
  @include error;
  
  margin: 0 0 $bottomMargin;

  input[type="file"] {
    display: none;
  }
  
  .fileButton {
    @include button;
  }

  .removeButton {
    @include button;
    background-color: $buttonStrongBgColor;
  }

  .preview {
    display: inline-block;
    padding-left: 20px;
    text-decoration: none;
    
    &:not([data-icon]) {
    }

    &[data-icon] {
      background: no-repeat right center;
      cursor: pointer;
      padding-right: 20px;

      @include buttonHover;
    }

    &[data-icon="image"] {
      background-image: $imageIconImage;      
      background-size: auto 16px;     
    }

    &[data-icon="other"] {
      background-image: $imageIconFile;
      background-size: auto 16px;     
    }
  }
}

.editor {
  @include label;
  @include error;

  margin: 0 0 $bottomMargin;
  position: relative;

  .head {
    margin-bottom: $labelBottomMargin;
    position: relative;
  }
  
  .preview {
    bottom: 0;
    position: absolute;
    right: 0;
  }

  .wrap {
    background: white;
    border: solid 1px $inputBorderColor;
    border-radius: $inputRadius;
  }

  div[role="application"] {
    border: none;
  }

  &[data-focus="on"] {
    .wrap {
      border-color: $inputFocusColor;
    }
  }

  &[data-input-error="true"] {
    .wrap {
      border-color: $inputErrorColor;
    }
  }
}

.date {
  @include label;
  @include error;
  
  margin: 0 0 $bottomMargin;
  z-index: 5;

  input {
    @include frame;

    &:focus {
      border-color: $inputFocusColor;
    }
  }

  &[data-time="true"] {
    .popper {
      :global(.react-datepicker) {
        min-width: 238px;
      }

      :global(.react-datepicker__time) {
        overflow: hidden;
      }

      :global(.react-datepicker__navigation--next) {
        right: 95px;
      }
    }
  }

  &[data-input-error="true"] {
    input {
      border-color: $inputErrorColor!important;
    }
  }
}

.link {
  opacity: 1;
  position: relative;
  text-decoration: none;

  @include buttonHover;
}

.readOnly {
  @include readOnly;
}
