@import '../constant.scss';

@mixin dndTitleBorder() {
  &:not([data-count="0"]) {
    border-top: solid 2px $chartBg;
  } 
}

.indent {
  padding-top: 28px;
}

.basicName {
  & > div {
    display: inline-block;
    margin-right: 20px;
  }
}

.basicprefecture {
  margin-right: 20px;
}

.basicContactWrap {
  @include dndTitleBorder;
  
  margin-bottom: 34px;
  width: 860px;
}

.basicContactName {
  width: 185px;
}

.basicContactTel,
.basicContactFax {
  width: 255px;
}
 
.basicStaffWrap {
  @include dndTitleBorder;
  
  margin-bottom: 34px;
  width: 1120px;
}

.basicStaffName,
.basicStaffNameKana {
  width: 200px;
}

.basicStaffPosition,
.basicStaffWork,
.basicStaffField {
  width: 185px;
}

.notification {
  padding-top: 0!important;
}

.notificationWrap {
  @include dndTitleBorder;
  
  width: 690px;
}

.notificationName {
  width: 185px;
}

.notificationEmail {
  width: 340px;
}


.annexAddButton {
  margin: 40px auto;
}

.annexRemoveButton {
  text-align: right;
}

.flatButton {
  @include buttonFlat;
  @include buttonHover;
}
