@import '../constant.scss';
@import '../image.scss';

.date {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.body {
  margin-top: 60px;
}

.contact {
  margin-top: 40px;
}

.file {
  list-style: none;
  margin: 40px 0 0;
  padding: 0;

  li {
    margin-bottom: 14px;

    a {
      background: no-repeat right center;
      cursor: pointer;
      display: inline-block;
      padding-right: 20px;
      text-decoration: none;
      transition: opacity .2s linear;

      &:hover {
        opacity: .4;
      }

      &[data-icon="image"] {
        background-image: $imageIconImage;      
        background-size: auto 16px;     
      }

      &[data-icon="other"] {
        background-image: $imageIconFile;
        background-size: auto 16px;     
      }
    }
  }
}