@import '../constant.scss';

.badge {
  border-radius: 2px;
  color: white;
  display: inline-block;
  font-size: 1rem;
  line-height: 18px;
  margin-right: 8px;
  padding: 0 10px;

  &[data-type="admin"] {
    background-color: $colorBlue;
  }
  
  &[data-type="member"] {
    background-color: $colorGreen;
  }

  &[data-type="guest"] {
    background-color: $colorKhaki;
  }

  &[data-type="new"] {
    background-color: $colorPink;
  }

  &[data-type="text"] {
    background-color: $colorPurple;
  }

  &[data-type="project"] {
    background-color: $colorYellow;
  }
}
