@import '../constant.scss';


$signinFrameW: 410px;

.header {
  width: $signinFrameW;
}

.section {
  width: $signinFrameW;

  & > small {
    color: $signinErrorColor;
    display: block;
    font-size: 1.4rem;
    margin-bottom: 30px;
    text-align: center;
  }

  & > div {
    margin-bottom: 10px;

    & > div {
      display: block;

      input[type="text"],
      input[type="password"] {
        width: 100%;
      }
    }

    &:nth-of-type(3) {
      margin-bottom: 0;
      margin-top: 20px;
      text-align: right;
    }
  }

  aside {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-top: 40px;
  }
}

.text {
  text-align: center;
  white-space: nowrap;
}

.buttonRow {
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  li:last-child {
    margin-left: auto;
  }

  .forgotPassword {
    font-size: 1.2rem;

    a {
      @include buttonHover;
      text-decoration: none;
    }
  }
}

.banner {
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
  text-align: center;
  white-space: nowrap;

  li {
    display: inline-block;
    margin: 0 10px 10px;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      height: 30px;
    }
  }
}
