@import '../constant.scss';


.count {
  display: inline-block;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    
    li {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        @include buttonHover;
      }
    }
  }
}