@import '../constant.scss';

.guest {
  bottom: 0;
  font-size: 1.2rem;
  height: $guestFooterH;
  left: 0;
  line-height: $guestFooterH;
  position: absolute;
  text-align: center;
  width: 100%;
}
