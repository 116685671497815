@import '../constant.scss';
@import '../image.scss';

.guest {
  align-items: center; 
  display: flex;
  margin: 20px auto;
  width: $guestFrameW;

  svg {
    display: inline-block;
    height: 80px;
    width: 80px;

    path {
      fill: $logoSvgTextColor;
      
      &:first-child {
        fill: $logoBgColor;
      }
    }
  }
  h1 {
    @include buttonHover;
    
    margin: 0;
    width: 100%;
  }

  a {
    background: no-repeat $imageLogoText;
    background-position: left 100px center;
    background-size: auto 44px;
    display: block;
  }
}


.member {
  border-bottom: solid 1px $headerBorderColor;
  height: $headerHeight;
  margin-bottom: $headerBottomMargin;

  h1 {
    line-height: $headerHeight;
    margin: 0;
    padding: 0 ($sideMarginContent + $sideSpaceContent);
    position: relative;
  }

  nav {
    align-items: flex-end;
    display: flex;
    height: 100%;
    padding: 0 ($sideMarginContent + $sideSpaceContent);
    
    span {
      padding: 20px 0;

      a {
        color: rgba(0, 0, 0, .4);
        cursor: pointer;
        display: inline-block;
        font-size: 1.2rem;
        overflow: hidden;
        padding-right: 30px;
        position: relative;
        text-decoration: none;
        transition: opacity .3s ease-out;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 250px;

        &:after {
          $size: 4px;

          border-bottom: solid 1px;
          border-right: solid 1px;
          content: "";
          display: block;
          height: $size;
          position: absolute;
          right: 16px;
          transform: rotate(-45deg);
          transform-origin: 50% 50%;
          top: 5px;
          width: $size;
        }

        &:hover {
          opacity: .4;
        }
      }

      &[data-last="true"] {
        padding-right: 0;

        a {
          color: $colorText;
          pointer-events: none;
          
          &:after {
            display: none;
          }
        }
      }

      &:last-child {
        margin-left: auto;
        padding: 0;

        a {
          color: $colorText;
          padding: 20px 0 20px 30px;
          &:after {
            display: none;
          }

          svg {
            $size: 26px;
            height: $size;
            left: 0;
            position: absolute;
            top: calc(50% - 13px);
            width: $size;
          }
        }
      }
    }
  }
}
