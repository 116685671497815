@import '../constant.scss';

.base {
  background: $modalBaseBgColor;
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  text-align: center;
  top: 0;
  transition:
    opacity .1s ease-out,
    height .1s linear .3s,
    width .1s linear .3s;
  width: 0;
  z-index: 100;

  .wrap {
    background: white;
    border-radius: 2px;
    box-shadow: $shadow;
    display: inline-block;
    margin: 80px 0 0;
    max-height: 80%;
    max-width: 50%;
    opacity: 0;
    padding: 40px;
    text-align: left;
    transform: translate3d(0, 0, 0) scale(.6, .6);
    /*
    transition:
      opacity .1s linear .5s,
      transform .1s linear .5s;
    */

    & > ul {
      list-style: none;
      margin: 0;
      padding: 0;

      & > li {
        &:first-child {
          margin-bottom: 40px;
          position: relative;
          text-align: center;
        }

        &:last-child {
          text-align: center;

          & > div { 
            margin: 0 10px;
          }

          a {
            text-align: center;
            width: 100px;
          }
        }
      }
    }
  }


  &[data-type="image"],
  &[data-type="image-file"] {
    .wrap {
      height: 80%;

      & > ul {
        height: 100%;
        overflow-y: scroll;
      }
    }

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &[data-type="preview"] {
    .wrap {
      height: calc(100vh - 160px);
      max-width: 60%;
      padding: 40px 4px 40px 40px;
      width: 60%;

      h2 {
        margin-top: 0;
        margin-bottom: 60px;
      }

      & > ul {
        height: 100%;
        overflow-y: scroll;

        & > li {
          margin-bottom: 0;
          padding-right: 36px;
          text-align: left;
        }
      }
    }
  }

  &[data-modal="true"] {
    height: 100%;
    opacity: 1;
    transition: opacity .2s ease-out;
    width: 100%;

    .wrap {
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1, 1);
      transition:
        opacity .4s ease-out .3s,
        transform .4s ease-out .3s;
    }
  }

  .close {
    $size: 20px;

    cursor: pointer;
    display: block;
    height: $size;
    position: absolute;
    right: 10px;
    top: 10px;
    width: $size;

    &:before,
    &:after {
      background: $modalCloseButtonColor;
      content: "";
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      transform-origin: center center;
      top: calc(50% - 1px);
      width: 100%;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

