@import '../constant.scss';

.areaTitle {
  display: flex;
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;

  li {
    &:last-child {
      margin-left: auto;

      & > div {
        margin-left: 20px;
      }
    }

    h3 {
      margin: 0;
    }
  }
}

.state {
  width: 120px;
}

.title {
  flex: 1;
}

.period {
  width: 240px;
}

.proposer {
  width: 240px;
}

.date {
  width: 120px;
}
 