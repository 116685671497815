@import '../constant.scss';
@import '../image.scss';

$sideSpace: 20px;

.nav {
  background-color: $colorSiteMenu;
  box-shadow: $shadow;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: $widthMenu;
  z-index: 20;

  header {
    border-bottom: solid 1px $menuBorderColor;
    height: $menuHeight;
    margin: 0 $sideSpace $menuBottomMargin;

    h1 {
      @include buttonHover;
      
      height: 100%;
      margin: 0;
    }

    .logo {
      background: no-repeat $imageLogoText;
      background-position: left 60px center;
      background-size: auto 28px;
      display: block;
      position: relative;
      text-decoration: none;
      width: 100%;

      svg {
        $size: 46px;

        margin-top: ($menuHeight - $size) / 2;
        height: $size;
        width: $size;

        path {
          fill: $logoSvgTextColor;
            
          &:first-child {
            fill: $logoBgColor;
          }
        }
      }
    }
  }

  ul {
    list-style: none;
    margin: 0 $sideSpace 40px;
    padding: 0;

    li {
      a {
        cursor: pointer;
        display: block;
        padding: 20px 0 20px 10px;
        position: relative;
        text-decoration: none;
        transition: all .3s linear;

        &:after {
          background-color: $menuHoverBorder;
          content: "";
          display: block;
          height: 80%;
          left: 0;
          position: absolute;
          top: 10%;
          transform: translate3d(0, 0, 0) scale(0, 0);
          transition: all .3s linear;
          width: 2px;
        }

        &[data-type="manual"] {
          display: inline-block;
          background: no-repeat right center;
          background-image: $imageIconFile;
          background-size: auto 16px;
          padding-right: 20px;
        }
 
        &:not(.active) {
          @include buttonHover;

          &:not([data-type="manual"]):hover:after {
            transform: translate3d(0, 0, 0) scale(1, 1)!important;
          }
        }
      }
    }
  }
}

.active {
  color: $menuActiveText!important;

  &:after {
    background-color: $menuActiveBorder!important;
    transform: translate3d(0, 0, 0) scale(1, 1)!important;
  }
}

.signout {
  border-top: solid 1px $menuBorderColor;
  bottom: 20px;
  margin: 0 $sideSpace;
  padding-top: 20px;
  position: absolute;
  text-align: center;
  width: calc(100% - (#{$sideSpace * 2}));

  span {
    $size: 20px;
    
    cursor: pointer;
    font-size: 1.2rem;
    line-height: $size;
    margin-right: $size;

    @include buttonHover;

    svg {
      display: inline-block;
      height: $size;
      margin-right: 4px;
      vertical-align: top;
      width: $size;
    }
  }
}
