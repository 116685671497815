@import '../constant.scss';

.basic {
  h2 {
    margin: 0 0 4px!important;
  }

  h3 {
    color: rgba(0, 0, 0, .3)!important;
    font-size: 1.1rem!important;
    margin: 0 0 40px!important;
  }

  .address {
    line-height: 1.2;
    list-style: none;
    margin: 0 0 26px;
    padding: 0;

    & > div {
      margin-bottom: 4px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .link {
    margin: 0 0 40px;
    
    a {
      display: inline-block;
      margin-right: 10px;
      transition: opacity .3s ease-out;

      &:hover {
        opacity: .4;
      }

      &:last-child {
        margin-right: 0;
      }

      &[data-icon="home"] svg,
      &[data-icon="tw"] svg,
      &[data-icon="fb"] svg,
      &[data-icon="ig"] svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  .staff,
  .contact {
    margin-top: 60px;

    h4 {
      border-bottom: solid 2px $chartBg;
      padding: 0 0 4px;
    }
  }
}










