@import '../constant.scss';

.nav,
.main {
  max-width: 900px;
}


.main {
  & > table,
  & > div {
    margin-bottom: 60px;
    line-height: 1.5;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    line-height: 1.5;
    margin: 0;
  }

  h2 {
    margin: 0 0 4px!important;
  }

  h3 {
    color: rgba(0, 0, 0, .3)!important;
    font-size: 1.1rem!important;
    margin: 0 0 40px!important;
  }

  .body {
    & > div {
      margin-bottom: 20px;
    }

    & > ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        div {
          background: no-repeat center rgba(0, 0, 0, .1);
          background-size: contain;
          padding-top: 100%;
          width: 100%;
        }

        p {
          color: rgba(0, 0, 0, .3);
          font-size: 1.2rem;
          margin-top: 4px;
          padding: 0 10px;
          text-align: center;
        }
      }
    }

    &[data-file="0"] {
      & > div {
        margin-bottom: 0;
      }
    }

    &[data-file="1"] {
      display: flex;

      & > div {
        flex: 1;
        line-height: 1.8;
      }

      & > ul {
        margin-left: 20px;
        width: 300px;
      }
    }

    &[data-file="2"] {
      ul {
        display: flex;
        justify-content: space-between;

        li {
          width: calc(50% - 10px);
          div {
            padding-top: 60%;
          }
        }
      }
    }

    &[data-file="3"] {
      ul {
        display: flex;
        justify-content: space-between;

        li {
          width: calc((100% / 3) - 10px);
        }
      }
    }
  }

  .member {

    td {
      padding: 0 20px 10px 0;
    }
  }
}

.at {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 10px;
    text-align: right;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
