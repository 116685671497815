$colorSiteMenu: white;
$colorTextError: #FF369E;
$colorTextWarning: #FFE66D;



$chartBg: rgba(150, 150, 150, .1);
$radiusContent: 2px;























$colorLogoSquare: #FF369E;
$colorLogoText: white;




/* contents */
$sideMarginContent: 20px;
$sideSpaceContent: 20px;


/* input */
$colorBorderInput: rgba(0, 0, 0, .4);
$colorLabelInput: rgba(0, 0, 0, .6);
$colorFocusInput: #00CBD6;
$colorCheckedInput: $colorFocusInput;
$colorRadioInput: $colorFocusInput;

$colorButtonInput: $colorFocusInput;
$radiusButton: 2px;
$radiusEditor: $radiusButton;





















/* app */
$colorGreen: #93CA82;
$colorKhaki: #88958D;
$colorBlue: #95B2DD;
$colorYellow: #E8C03A;
$colorPurple: #755990;
$colorPink: #FF8585;
$colorText: #322D2B;


/*
$colorPink: #FF369E;
*/

$htmlBgColor: #F3F3F3;
$h3Color: $colorBlue;

$logoBgColor: #CA9AA6;
$logoSvgTextColor: white;

$radius: 2px;
$shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);

$hoverLinkOpacity: .6;
$hoverLinkTransitionSec: .3s;
$hoverLinkTransitionEasing: ease-out;




/* section */
$sectionRadius: $radius;
$sectionTabBgColor: white;
$sectionSelectedTabColor: $colorBlue;
$sectionTabButtonBgColor: $colorBlue;
$sectionTabRegisterBgColor: rgba(200, 200, 200, .2);
$sectionStrongColor: $colorPink;



/* input */
$inputRadius: $radius;
$buttonBgColor: $colorBlue;
$buttonStrongBgColor: $colorPink;
$buttonLightBgColor: $colorYellow;
$inputFocusColor: $colorBlue;
$inputErrorColor: $colorPink;
$inputRequiredColor: $colorPink;
$inputBorderColor: rgba(0, 0, 0, .4);
$inputLabelColor: rgba(0, 0, 0, .6);
$inputCheckedColor: $colorBlue;

/* header */
$headerBorderColor: rgba(0, 0, 0, .2);
$headerHeight: 60px;
$headerBottomMargin: 20px;

/* menu */
$widthMenu: 240px;
$menuBorderColor: $headerBorderColor;
$menuHeight: $headerHeight;
$menuBottomMargin: $headerBottomMargin;
$menuActiveBorder: $colorBlue;
$menuActiveText: $colorBlue;
$menuHoverBorder: $colorBlue;



/* chart */
$chartBorder: rgba(0, 0, 0, .2);
$chartSpace: 20px;
$chartPrivateBgColor: #FFE4E4;
$chartOddBgColor: rgba(150, 150, 150, .1);

/* dnd */
$dndThumbBgColor: $colorBlue;
$dndAddButtonBgColor: $colorBlue;

$dndRemoveButtonBgColor: $colorPink;
$dndStrongColor: $colorPink;

/* modal */
$modalBaseBgColor: rgba(0, 0, 0, .5);
$modalCloseButtonColor: $colorPink;

/* ---- Guest ---- */
$guestFrameW: 306px;
$guestFooterH: 60px;

/* screen */
$signinErrorColor: $colorPink;


/* mixin */
@mixin buttonHover() {
  transition: opacity $hoverLinkTransitionSec $hoverLinkTransitionEasing;

  &:hover {
    opacity: $hoverLinkOpacity;
  }
}

@mixin buttonFlat() {
  cursor: pointer;
  border: dotted 2px $chartBorder;
  color: $chartBorder;
  font-size: 1.2rem;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

@mixin chartIndent() {
  padding-left: 50px!important;
  position: relative;

  &:before {
    $size: 10px;

    border-left: solid 2px $colorText;
    border-bottom: solid 2px $colorText;
    content: "";
    display: block;
    height: $size;
    left: 30px;
    position: absolute;
    top: 18px;
    width: $size;
  }
}
