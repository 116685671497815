@import '../constant.scss';


@mixin tab($display: inline-block) {
  background: $sectionTabButtonBgColor;
  border-right: solid 1px white;
  color: white;
  cursor: pointer;
  display: $display;
  height: 60px;
  line-height: 20px;
  opacity: 1;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;

  span {
    display: inline-block;
  }

  @include buttonHover;
            
  &[data-active="true"] {
    background: $sectionTabBgColor;
    color: $sectionSelectedTabColor;
    pointer-events: none;
  }

  &:last-child {
    border-right: none;
  }
}

.tabLink {
  margin: 0 $sideMarginContent 20px;
  padding: 0!important;

  .head {
    display: flex;
    margin-bottom: 20px;
    
    a {
      @include tab;
      width: 100%;
    }
  }

  .wrap {
    padding: $sideSpaceContent;
  }

  // &[data-padding="false"] {
  //   .head {
  //     margin-bottom: 0;
  //   }

  //   .wrap {
  //     padding: 0;
  //   }
  // }
}


.tab {
  background: $sectionTabBgColor;
  border-radius: $sectionRadius;
  box-shadow: $shadow;
  margin: 0 $sideMarginContent 20px;
  overflow: hidden;
  padding: 0!important;

  .nav {
    em,
    a {
      @include tab;
    }
  }

  .ul {
    background: $sectionTabBgColor;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    .li {
      padding: 40px $sideSpaceContent;
      width: 100%;

      &[data-active="false"] {
        height: 0;
        left: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
      }

      &[data-active="true"] {
        opacity: 1;
        position: relative;
        transition: opacity .6s ease-out;
      }
    }
  }
  
  &[data-type="register"] {
    background: transparent;
    margin: 0 0 20px!important;

    .nav {
      a[data-active="true"] {
        background: $sectionTabRegisterBgColor;
      }
      
      .addButton {
        position: relative;

        &:before,
        &:after {
          background: white;
          content: "";
          display: block;
          height: 2px;
          left: 25%;
          position: absolute;
          top: 50%;
          width: 50%;
        }
      
        &:after {
          transform-origin: 50% 50%;
          transform: rotate(90deg);
        }
      }
    } 
    .ul {
      background: $sectionTabRegisterBgColor;

      .li {
        padding-left: $sideSpaceContent + $sideMarginContent;
        padding-right: $sideSpaceContent + $sideMarginContent;
      }
    }
  }
}

.register {
  margin: 0 $sideMarginContent!important;
  padding: $sideSpaceContent!important;
  padding-bottom: 0!important;

  & > ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    & > li {
      margin-right: 20px;
    }
  }
}

.strong {
  &[data-border="in"] {
    margin: 40px ($sideMarginContent * 2)!important;
  }
  
  border: solid 1px $sectionStrongColor;
  color: $sectionStrongColor;
  line-height: 1.5;
}