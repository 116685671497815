@media print{
  html,
  body,
  #root {
    background: white;
    height: auto;
  }

  main {
    height: auto;
    padding-left: 0!important;

    & > nav {
      display: none;
    }

    & > article {
      height: auto;
      overflow: auto;
      min-width: auto;

      & > header,
      & > nav {
        display: none;
      }
      & > section {
        box-shadow: none!important;
        margin: 0!important;
      }
    }
  }

  *[data-print="off"],
  .printOff {
    display: none;
  }


  html[data-url="/project"],
  html[data-url="/project/draft"],
  html[data-url="/project/join"] {
    #project-tab-area {
      display: none;
    }

    section[data-type="card"] {
      display: none;

      & > ul {
        &:nth-child(1) {
          li:nth-child(2) {
            display: none;
          }
        }
        
        &:nth-child(2) {
          li:nth-child(1) {
            display: none;
          }
        }

        &[data-dnd="frame"] {
          li {
            & > div > span:nth-child(1) {
              display: none;
            }
          }
        }
      }

      & > div {
        display: none;
      }
    }

    &[data-project-target="new"] #new, 
    &[data-project-target="recruit"] #recruit, 
    &[data-project-target="yomiuri"] #yomiuri, 
    &[data-project-target="any"] #any {
      display: block;
    }
  }

  section[data-print="project-detail"] {
    font-size: 1.6rem;

    h2 {
      font-size: 2.4rem;
    }

    h3 {
      font-size: 1.4rem;
    }
 
    h4 {
      font-size: 1.4rem;
    }
  }
}
