@import-normalize;
@import './constant.scss';
* {
  box-sizing: border-box;
  word-break: break-all;
}

input::-ms-clear {
  visibility: hidden;
}

a {
  color: $colorText;
}

html {
  background-color: $htmlBgColor;
  color: $colorText;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  font-size: 10px;
  height: 100vh;
  margin: 0;
  min-width: 1024px;
  padding: 0;
}

body {
  font-size: 1.4rem;
  height: 100vh;
  margin: 0;
  padding: 0;
}

button,
input,
textarea,
select {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

#root {
  height: 100%;
  position: relative;

  main[data-signin="false"] {
    height: 100%;
    position: relative;

    article {
      min-height: 100%;
      padding-bottom: $guestFooterH;
      position: relative;
    }

    section {
      background: white;
      border-radius: $sectionRadius;
      box-shadow: $shadow;
      margin: 0 auto;
      padding: 40px;
    }
  }

  main[data-signin="true"] {
    height: 100%;
    padding-left: $widthMenu;
    position: relative;

    article {
      flex-basis: auto;
      height: 100%;
      position: relative;
      min-width: 1200px;
      width: 100%;
      z-index: 1;

      & > nav {
        margin: 0 $sideMarginContent;
        padding: $sideSpaceContent;
        text-align: right;

        & > div {
          margin-left: 20px;
        }

        &[data-align="left"] {
          text-align: left;

          & > div {
            margin-left: 0;
            margin-right: 20px;
          }
        }
      }

      section {
        margin: 0 $sideMarginContent 20px;
        padding: $sideSpaceContent;

        &[data-type="card"] {
          background: white;
          border-radius: $sectionRadius;
          box-shadow: $shadow;
        }

        &[data-padding="false"] {
          padding: 0;
        }

        aside {
          font-size: 1.2rem;
          line-height: 1.5;
          margin-bottom: 20px;
        }
      }

      h2 {
        margin-top: 0;
      }

      h3 {
        color: $h3Color;
        font-size: 1.4rem;
        margin: 0 0 10px;
      }

      h4 {
        font-size: 1.2rem;
        margin: 0 0 10px;
      }
    }
  }
}

#icon-signout path,
#icon-account path,
#icon-home path,
#icon-ig path,
#icon-fb path,
#icon-tw path {
  fill: $colorText;
}

i {
  display: inline-block;
  height: 50px;
  position: relative;
  vertical-align: top;
  width: 16px;

  &:after {
    border-bottom: solid 1px $colorBorderInput;
    content: "";
    display: block;
    position: absolute;
    bottom: 15px;
    width: 50%;
    left: 25%;
  }
}

dfn {
  color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  font-style: normal;
  font-size: 1.1rem;
  vertical-align: top;
  padding: 8px 0 0 4px;
}

@import './print.scss';
