@import '../constant.scss';

.base {
  height: 0;
  left: 0;
  position: fixed;
  top: -2px;
  width: 100%;
  z-index: 100;

  p {
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
    line-height: 60px;
    margin: 0;
    padding: 10px 40px;
    text-align: center;
    transform: translate3d(0, -100px, 0);

    &[data-type="send"],
    &[data-type="save"],
    &[data-type="remove"] {
      background-color: #322D2B;
      color: #F7FFF7;
    }

    &[data-type="warning"],
    &[data-type="error"] {
      background-color: #FF6B6B;
      color: #F7FFF7;
    }
  }
}
