@import '../constant.scss';

.areaTitle {
  display: flex;
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;

  li {
    &:last-child {
      margin-left: auto;
    }

    h3 {
      margin: 0;
    }
  }
}


.name {
  flex: 1;

  &[data-indent="1"] {
    @include chartIndent;
  }
}

.link {
  margin-left: auto;
  width: 160px;
  
  a {
    display: inline-block;
    margin-right: 10px;
    transition: opacity .3s ease-out;
    
    &:hover {
      opacity: .4;
    }

    &:last-child {
      margin-right: 0;
    }
     
    &[data-icon="home"] svg,
    &[data-icon="tw"] svg,
    &[data-icon="fb"] svg,
    &[data-icon="ig"] svg {
      height: 20px;
      width: 20px;
    }
  }
}

.tel,
.fax {
  margin-left: auto;
  width: 140px;
}