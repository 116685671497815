@import '../constant.scss';

@mixin buttonBase() {
  appearance: none;
  background-color: $dndAddButtonBgColor;
  box-shadow: $shadow;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  margin-left: 10px;
  outline: none;
  position: relative;
  text-decoration: none;
  width: 30px;

  @include buttonHover;
}

@mixin removeButton() {
  button[data-type="-"] {
    @include buttonBase;
    background-color: $dndRemoveButtonBgColor;

    &:after {
      background: white;
      content: "";
      display: block;
      height: 2px;
      left: 25%;
      position: absolute;
      top: 50%;
      width: 50%;
    }
  }
}

@mixin addButton() {
  button[data-type="+"] {
    @include buttonBase;

    &:before,
    &:after {
      background: white;
      content: "";
      display: block;
      height: 2px;
      left: 25%;
      position: absolute;
      top: 50%;
      width: 50%;
    }

    &:after {
      transform-origin: 50% 50%;
      transform: rotate(90deg);
    }
  }
}


.head {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    color: $colorLabelInput;
    font-size: 1.2rem;
    font-weight: bold;
    padding: $chartSpace;
    text-align: left;

    &[data-action="false"] {
      display: none;
    }
  }
}

.frame {
  list-style: none;
  margin: 0;
  padding: 0;

  & > li {
    transition: all .2s;

    &[data-start="true"] {
      opacity: .2;
    }

    &[data-over="up"],
    &[data-over="down"] {
      position: relative;
      
      &:after {
        border: dotted 2px lightgray;
        box-sizing: border-box;
        content: "";
        display: block;
        height: 30px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }

    &[data-over="up"] {
      padding-top: 60px;
      &:after {
        top: 10px;
      }
    }

    &[data-over="down"] {
      padding-bottom: 60px;
      &:after {
        bottom: 10px;
      }
    }
    .button {
      align-self: center;
      flex: 1;
      padding-left: 10px;
      text-align: right;
      white-space: nowrap;

      @include addButton;
      @include removeButton;
    }

    .wrap {
      display: flex;

      & > span {
        padding: $chartSpace;
      }
    }

    &:nth-child(odd) .wrap {
      background-color: $chartOddBgColor;
    }

    &[data-state="private"] .wrap {
      background: $chartPrivateBgColor;
    
      span[data-strong="true"] {
        color: $dndStrongColor;
      }
    }
  }

  &[data-sheat="true"] {
    li {
      position: relative;
      overflow: hidden;

      &:before {
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 99;
      }
    }
  }

  i {
    height: 30px;
  }

  div[data-component="input"] {
    margin-bottom: 0;
  }
}

.tbody {
  .tr {
    padding-bottom: 20px;

    td {
      transition: all .2s;
    }

    &[data-start="true"] {
      opacity: .2;
    }

    &[data-over="down"] td {
      padding-bottom: 40px;
    }

    &[data-over="up"] td {
      padding-top: 40px;
    }

    .button {
      padding-left: 10px;
    }
  }

  &[data-sheat="true"] {
    .row {
      position: relative;
      overflow: hidden;
    }
  }

  i {
    height: 30px;
  }

  div[data-component="input"] {
    margin-bottom: 0;
  }
}

.thumb {
  position: relative;
  padding: $chartSpace;

  &[data-action="false"] {
    display: none;
  }
  
  span,
  a {
    $size: 12px;

    border-radius: 2px;
    cursor: move;
    display: inline-block;
    height: $size;
    left: 50%;
    margin-left: -($size / 2);
    margin-top: -($size / 2);
    overflow: hidden;
    position: absolute;
    top: 50%;
    width: $size;

    &:before,
    &:after {
      background: $dndThumbBgColor;
      content: "";
      display: block;
      height: ($size / 2) - 1px;
      position: absolute;
      width: 100%;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }
  }
}

