@import '../constant.scss';

@mixin link() {
  a {
    cursor: pointer;
    display: inline-block;
    line-height: 40px;
    font-size: 1.2rem;
    text-decoration: none;
    text-align: center;
    transition: opacity .3s ease-out;
    
    &:hover {
      opacity: .4;
    }

    span {
      display: inline-block;
      padding: 0 20px;
      position: relative;

      &:before {
        border-left: solid 1px;
        border-bottom: solid 1px;
        content: "";
        display: block;
        height: 6px;
        left: 0;
        margin-top: -4px;
        position: absolute;
        top: 50%;
        transform: rotate(-45deg);
        transform-origin: 50% 50%;
        width: 6px;
      }
    }
  }
}

.nav {
  background: white;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    text-align: center;
    @include link;
  }
}


.back {
  margin-top: 20px;
  text-align: right;
  
  @include link;
  a {
    font-size: 1rem;
    line-height: 1;

    span {
      padding: 0  0 0 20px;

      &:before {
        margin-top: 0;
        transform: rotate(135deg);
      }
    }
  }
}
